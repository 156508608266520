var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "flex" }, [
      _c(
        "div",
        { staticClass: "w-full" },
        [
          _vm.showIndex
            ? _c(
                "vs-divider",
                {
                  staticClass: "mt-2",
                  attrs: { position: "left", background: "success" },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("questionnaire_corrector.criteria")) +
                      " : " +
                      _vm._s(_vm.index + 1) +
                      " "
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.showButtonDelete
        ? _c(
            "div",
            { staticClass: "flex justify-end max-w-50" },
            [
              _c("vs-button", {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: {
                      content: _vm.$t("action.delete"),
                      delay: { show: 500, hide: 100 },
                    },
                    expression:
                      "{\n            content: $t('action.delete'),\n            delay: { show: 500, hide: 100 }\n          }",
                  },
                ],
                attrs: {
                  color: "danger",
                  type: "flat",
                  "icon-pack": "feather",
                  icon: "icon-trash",
                },
                on: {
                  click: function ($event) {
                    return _vm.deleteCriteria()
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ]),
    _c(
      "div",
      { staticClass: "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-2" },
      [
        _c(
          "div",
          { staticClass: "flex w-full w-5/6" },
          [
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|max:191",
                  expression: "'required|max:191'",
                },
              ],
              staticClass: "w-full required",
              attrs: {
                label: _vm.$t("questionnaire_corrector.evaluation.criteria"),
              },
              model: {
                value: _vm.criteriaModel.title,
                callback: function ($$v) {
                  _vm.$set(_vm.criteriaModel, "title", $$v)
                },
                expression: "criteriaModel.title",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "flex w-1/6" },
          [
            _c(
              "vx-input-group",
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|numeric",
                      expression: "'required|numeric'",
                    },
                  ],
                  staticClass: "required",
                  attrs: {
                    lang: "pt-BR",
                    type: "number",
                    label: _vm.$t("questionnaire_corrector.evaluation.weight"),
                  },
                  on: {
                    change: _vm.scoreChange,
                    keyup: _vm.validateKeyPresses,
                  },
                  model: {
                    value: _vm.criteriaModel.weight,
                    callback: function ($$v) {
                      _vm.$set(_vm.criteriaModel, "weight", $$v)
                    },
                    expression: "criteriaModel.weight",
                  },
                }),
                _c("template", { slot: "append" }, [
                  _c(
                    "div",
                    { staticClass: "append-text btn-addon flex items-end" },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "input-group-text includeIcon includeIconOnly vs-component",
                        },
                        [_vm._v("%")]
                      ),
                    ]
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
      ]
    ),
    _c("div", { staticClass: "vx-row mt-2" }, [
      _c(
        "div",
        { staticClass: "vx-col sm:w-1/1 w-full mb-2" },
        [
          _c("complex-editor", {
            attrs: { editor_data: _vm.criteriaModel.description },
            on: {
              "update:editor_data": function ($event) {
                return _vm.$set(_vm.criteriaModel, "description", $event)
              },
              "complex-editor-is-open": _vm.editorIsOpen,
            },
          }),
        ],
        1
      ),
    ]),
    _vm.showButtonAdd
      ? _c(
          "div",
          { staticClass: "w-full flex gap-2 justify-end" },
          [
            _c(
              "vs-button",
              {
                directives: [
                  {
                    name: "permission",
                    rawName: "v-permission",
                    value: "questionnaire_correctors.edit",
                    expression: "'questionnaire_correctors.edit'",
                  },
                ],
                attrs: {
                  disabled: !_vm.validateForm,
                  type: "border",
                  icon: "add",
                },
                on: {
                  click: function ($event) {
                    return _vm.addCriteria()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("adicionar")) + " ")]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }